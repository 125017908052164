<script>
import { Client, RegistrationState } from '@pusher/push-notifications-web';
import env from '@/env';

const {
  PERMISSION_DENIED,
  PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS,
  PERMISSION_GRANTED_REGISTERED_WITH_BEAMS,
  PERMISSION_PROMPT_REQUIRED
} = RegistrationState;

export default {
  /**
   * Provide is a function here so that we can expose our reactive client object
   * without this the client properties won't be reactive.
   */
  provide() {
    return {
      pushNotificationsClient: this.pushNotificationsClient
    };
  },
  props: {
    shopId: {
      type: Number,
    }
  },
  data() {
    return {
      // internal pusher notifications web client for use within this component
      client: null,
      // provided client to consumers for interacting with push notifications
      // available as scoped slot or provide/inject
      pushNotificationsClient: {
        addDeviceInterest: this.addDeviceInterest,
        removeDeviceInterest: this.removeDeviceInterest,
        getInterestKey: this.getInterestKey,
        isDenied: this.isDenied,
        isLoading: false,
        error: null,
        deviceInterests: []
      },
    };
  },
  mounted() {
    try {
      this.client = new Client({
        instanceId: env('VITE_PUSHER_NOTIFICATIONS_INSTANCE_ID'),
      });
    } catch (e) {
      // Creating the client will throw in Safari and all other unsupported browsers.
      return this.onError('Push notifications not currently supported for this browser.');
    }

    return this.client.getRegistrationState()
      .then(async state => {
        this.isLoading = true;
        if (state === PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS) {
          await this.client.start();
          this.pushNotificationsClient.deviceInterests = await this.client.getDeviceInterests();
        }

        if (state === PERMISSION_GRANTED_REGISTERED_WITH_BEAMS) {
          this.pushNotificationsClient.deviceInterests = await this.client.getDeviceInterests();
        }
        this.isLoading = false;
      })
      .catch((e) => this.onError(e));
  },
  methods: {
    modifyInterest(method, interest) {
      this.isLoading = true;

      return this.client.getRegistrationState()
        .then(state => {
          if (state === PERMISSION_DENIED) {
            return Promise.reject(`Permission must be given to allow notifications`);
          }
          return state;
        })
        .then(async state => {
          if (state === PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS
            || state === PERMISSION_PROMPT_REQUIRED
          ) {
            await this.client.start();
            return this.client.getRegistrationState();
          }
          return state;
        })
        // Final check that they have accepted the prompt, we're registered and granted permission.
        .then(state => {
          if (state === PERMISSION_GRANTED_REGISTERED_WITH_BEAMS) {
            return this.client[method](interest);
          }
          return Promise.reject(`Failed to get permissions and register for notifications`);
        })
        // Fetch updated device interests to ensure consistent state
        .then(async () => {
          this.pushNotificationsClient.deviceInterests = await this.client.getDeviceInterests();
          this.isLoading = false;
          return true;
        })
        .catch((e) => this.onError(e));
    },
    /**
     * Add an interest to the device
     *
     * @param {String} interest - key of the interest to add
     * @returns {Promise<Boolean>}
     */
    async addDeviceInterest(interest) {
      if (this.client) {
        return this.modifyInterest('addDeviceInterest', interest);
      }
      return false;
    },

    /**
     * Remove an interest from the device
     *
     * @param {String} interest - key of the interest to remove
     * @returns {Promise<Boolean>}
     */
    async removeDeviceInterest(interest) {
      if (this.client) {
        return this.modifyInterest('removeDeviceInterest', interest);
      }
      return false;
    },

    /**
     * Make an interest key string from the interest name
     *
     * @param {String} name - The name or topic of the push notification,i.e manual-review
     * @returns {String|null} - The interest key, i.e manual-review-1
     */
    getInterestKey(name) {
      return this.shopId ? `${name}-${this.shopId}` : null;
    },

    /**
     * Set the error state, clear the loading state, and clear the device interests
     *
     * @param {String|Error} e
     * @returns {void}
     */
    onError(e = 'Something went wrong') {
      console.error(e);
      this.isLoading = false;
      this.pushNotificationsClient.error = e;
      this.pushNotificationsClient.deviceInterests = [];
    }
  },
  render() {
    return this.$slots.default({});
  }
};
</script>
