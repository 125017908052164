import integrations from '@/util/api/integrations';
import { integrations as integrationsList } from '@/util/schemas/integrations';
import Alloy from 'alloy-frontend';

function indexFromName(state, name) {
  return state.list.findIndex((integration) => {
    return integration.name === name;
  });
}

export default {
  namespaced: true,
  state: {
    list: [],
    loading: true,
    errorMessage: null,
    successMessage: null,
  },
  mutations: {
    setIntegrations(state, integrations) {
      state.list = integrations;
      state.loading = null;
    },
    enableIntegration(state, integrationName) {
      state.list[indexFromName(state, integrationName)].enabled = true;
      state.loading = null;
    },
    disableIntegration(state, integrationName) {
      state.list[indexFromName(state, integrationName)].enabled = false;
      state.loading = null;
    },
    updateIntegration(state, integrationData) {
      state.list[indexFromName(state, integrationData.name)] = integrationData;
      state.loading = null;
    },
    startLoading(state, integrationName) {
      state.errorMessage = null;
      state.loading = integrationName;
    },
    setError(state, errorMessage) {
      state.errorMessage = errorMessage;
      state.loading = null;
    },
    setSuccess(state, successMessage) {
      state.successMessage = successMessage;
      state.loading = null;
    }
  },
  actions: {
    async loadIntegrations({ commit }) {
      const res = await integrations.get();
      commit('setIntegrations', res.data);
    },
    async enableIntegration({ commit, state }, { integrationName }) {
      commit('enableIntegration', integrationName);

      const integration = state.list[indexFromName(state, integrationName)];
      if (integration.connectEndpoint) {
        window.location.href = integration.connectEndpoint;
      }

      const alloyIntegrations = [integrationsList.BRIGHTPEARL];
      if (alloyIntegrations.includes(integrationName)) {
        const alloy = Alloy();

        alloy.setToken(integration.config.jwt);
        alloy.install({
          integrationId: integration.config.integrationId,
          callback: (resp) => {
            if (resp.success) {
              integrations.upsert({
                name: integration.name,
                state: 'active',
                key: integration.config.key,
                config: integration.config,
              });
            }
          }
        });
      }
    },
    async redirectToGorgias({ commit }, { integrationName, subdomain, createDeliveryErrorTickets }) {
      const res = await integrations.getConnectEndpoint(integrationName, subdomain, createDeliveryErrorTickets);
      if (res.data?.errors) {
        commit('setError', res.data?.errors?.join(' | '));
        return false;
      } else {
        window.localStorage.setItem('createDeliveryErrorTickets', createDeliveryErrorTickets);
        window.location.href = res.data?.authorizationUrl;
      }
    },
    async disableIntegration({ commit, dispatch, state }, { integrationName }) {
      commit('disableIntegration', integrationName);
      commit('startLoading', integrationName);

      const integration = state.list[indexFromName(state, integrationName)];

      if (integrationName === integrationsList.GORGIAS) {
        try {
          const res = await integrations.uninstall('gorgias', undefined);
          dispatch('updateIntegration', res, integrationName);
        } catch (error) {
          commit('enableIntegration', integrationName);
          console.error(error);
        }
      }

      if (integration.disconnectEndpoint) {
        commit('disableIntegration', integrationName);
        window.location.href = integration.disconnectEndpoint;
      } else if (integration.thirdPartyApp) {
        const integrationLowerCaseName = integration.name.toLowerCase();
        const shopifyDomain = integration.shopifyDomain;

        try {
          const res = await integrations.uninstall(
            integrationLowerCaseName,
            shopifyDomain
          );
          dispatch('updateIntegration', res, integrationName);
        } catch (error) {
          commit('enableIntegration', integrationName);
          console.error(error);
        }
      } else {
        const item = state.list[indexFromName(state, integrationName)];
        const disabledIntegrationPayload = {
          name: item.name,
          state: 'inactive',
          key: item.config.key,
          config: item.config,
        };
        const res = await integrations.upsert(disabledIntegrationPayload);
        dispatch('updateIntegration', res, integrationName);
      }
    },
    updateIntegration({ commit }, res, integrationName) {
      if (res.data?.errors) {
        commit('enableIntegration', integrationName);
        return false;
      } else {
        commit('updateIntegration', res.data);
        return true;
      }
    },
    async saveIntegrationConfig({ commit }, data) {
      commit('startLoading', data.name);
      const res = await integrations.upsert(data);
      if (res.data.errors) {
        commit('setError', res.data.errors.join(' | '));
        return false;
      } else {
        commit('updateIntegration', res.data.service);
        return true;
      }
    },
    async runTestForIntegration({ commit }, data) {
      commit('startLoading', data.name);
      const res = await integrations.test(data);
      if (res.data.success) {
        commit('setSuccess', res.data.message);
      } else {
        commit('setSuccess', null);
        commit('setError', res.data.message);
      }
    },
    async enableDisclaimableIntegration({ commit }, integration) {
      commit('startLoading', integration.name);
      const res = await integrations.upsert({
        name: integration.name,
        state: 'active',
        key: integration.config.key,
        config: integration.config,
      });
      if (res.data.errors) {
        commit('setError', res.data.errors.join(' | '));
      } else {
        commit('enableIntegration', integration.name);
      }
    }
  },
  getters: {
    getByName(state, integrationName) {
      return state.list[indexFromName(state, integrationName)];
    },
    isIntegrationEnabled: (state) => (integrationName) => {
      const index = indexFromName(state, integrationName);
      return Boolean(state.list[index]?.enabled);
    }
  }
};
