import {
  BaseButton,
  BaseCard,
  InputWrapper,
} from '@loophq/component-library';
import { BaseText } from '@loophq/ui';

//TODO: Switch component library versions wherep ossible once we have reconciled all components
import SvgIcon from '@/components/SvgIcon.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import StyledButton from '@/components/forms/StyledButton.vue';
import PureSaveBar from '@/components/structure/PureSaveBar.vue';


export const registerGlobalComponents = (app: any): void => {
  app.component('SvgIcon', SvgIcon);
  app.component('TransitionExpand', TransitionExpand);
  app.component('InputWrapper', InputWrapper);
  app.component('StyledButton', StyledButton);
  app.component('PureSaveBar', PureSaveBar);

  app.component('BaseButton', BaseButton);
  app.component('BaseCard', BaseCard);

  //New components
  app.component('BaseText', BaseText);

  /* app.component('navigation', require('./global/navigation/navigation.vue'));
  app.component('navItem', require('./global/navItem/navItem.vue'));
  app.component('alert', require('./global/alert/alert.vue'));
  app.component('modal', require('./global/modal/modal.vue'));
  app.component('addressModal', require('./global/addressModal/addressModal.vue'));
  app.component('subheader', require('./global/subheader/subheader.vue'));
  app.component('saveBar', require('./global/saveBar/saveBar.vue'));
  app.component('pureSaveBar', require('./global/pureSaveBar/pureSaveBar.vue'));
  app.component('settingPreview', require('./global/settingPreview/settingPreview.vue'));
  app.component('settingForm', require('./global/settingForm/settingForm.vue'));
  app.component('StyledButton', require('./global/StyledButton/StyledButton.vue')); */
};
