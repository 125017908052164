import SkeletonLayout from '@/components/skeletons/SkeletonLayout';

export default [
  {
    path: '/preferences',
    component: () => import('@/pages/Preferences/PreferencesPage'),
    name: 'Preferences',
    meta: {
      layout: 'app',
      title: 'Preferences',
      skeleton: () => SkeletonLayout,
    },
  }
];
