import { cloneDeep, isFunction } from 'lodash';
import Store from '@/store';
import { permissions } from '@/util/schemas/permissions';
import { featureFlags } from '@/util/schemas/featureFlags';

// TODO: convert this to inline validator functions
// See the disabled() validator for how that works in practice
const checkPermissions = (data, item) => {
  const {
    userLevel,
    billingPermission,
    permissions
  } = data;
  const hasPermission = item.permission ? permissions.includes(item.permission) : true;
  const hasFeature = item.featureFlag ? Store.getters.hasFeature(item.featureFlag) : true;

  if ('isVisible' in item) {
    return item.isVisible;
  }

  if (!hasFeature) {
    return false;
  }

  // Parents are always allowed intially
  if (item.items) {
    return true;
  }

  // Billing special case
  if (item.billingPermission) {
    return !!billingPermission;
  }

  // If we're setting this to hidden for any reason
  if (item.hidden) {
    return false;
  }

  // Regular access level = 1, pro/plus = 2
  return (item.accessLevel <= userLevel) && hasPermission;
};

export const getFirstRouteTheUserHasAccessTo = () => {
  let item = Store.getters['sidebarNavigation/visibleItems']?.[0];

  if (Store.getters.hasFeature(featureFlags.MULTI_PRODUCT_NAV_ENABLED)) {
    if (
      Store.getters.hasFeature(featureFlags.MISSION_CONTROL_ENABLED)
      && Store.getters.hasPermission(permissions.MANAGE_RETURNS)
      && Store.getters.hasPermission(permissions.VIEW_ANALYTICS)
    ) {
      item = { url: '/home' };
    } else if (Store.getters.hasPermission(permissions.MANAGE_RETURNS)) {
      item = { url: '/returns' };
    } else if (Store.getters.hasPermission(permissions.VIEW_ANALYTICS)) {
      item = { url: '/analytics' };
    } else {
      item = { url: '/' };
    }
  }

  return item ? item.url : '/';
};

const evaluateProperties = (item, data) => {
  return Object.keys(item).reduce((acc, cur) => {
    const value = isFunction(item[cur]) ? item[cur](data) : item[cur];
    return {
      ...acc,
      [cur]: value
    };
  }, {});
};

const navigation = {
  namespaced: true,
  state: {
    items: [
      {
        accessLevel: 1,
        name: 'Returns',
        url: '/returns',
        icon: 'returns',
        bubble: () => Store.state.returns.feed.newReturns || null,
        permission: permissions.MANAGE_RETURNS,
        items: [
          {
            accessLevel: 1,
            name: 'Return processing',
            url: '/returns'
          },
          {
            accessLevel: 1,
            name: 'Find an order',
            url: '/returns/find-order'
          },
          {
            accessLevel: 1,
            name: 'Warranty processing',
            url: '/returns/warranties',
            isVisible: ({ getters }) => getters.getSetting('WARRANTIES_ENABLED'),
          },
          {
            accessLevel: 1,
            name: 'Return events feed',
            url: '/returns/feed'
          },
        ],
        isVisible: ({ getters }) => {
          return getters.hasPermission(permissions.MANAGE_RETURNS);
        },
      },
      {
        accessLevel: 1,
        name: 'Analytics',
        url: '/analytics',
        icon: 'reports',
        permission: permissions.VIEW_ANALYTICS,
        items: [
          {
            accessLevel: 1,
            name: 'Reports',
            url: '/analytics/reports',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING),
          },
          {
            accessLevel: 1,
            name: 'Overview',
            url: '/analytics/overview',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Value Impact',
            url: '/analytics/loopvalueimpact',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Products',
            url: '/analytics/products',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Customers',
            url: '/analytics/customers',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Operations',
            url: '/analytics/operations',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Shipping and Logistics',
            url: '/analytics/shippinglogistics',
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Happy Returns',
            url: '/analytics/happyreturns',
            isVisible: ({ getters }) => getters.getSetting('INTEGRATION_HAPPY_RETURNS_ENABLED'),
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            accessLevel: 1,
            name: 'Happy Returns Shipments',
            url: '/analytics/happyreturnsshipments',
            isVisible: ({ getters }) => getters.getSetting('INTEGRATION_HAPPY_RETURNS_ENABLED'),
            disabled: ({ getters }) => !getters.hasPermission(permissions.MANAGE_REPORTING)
          },
        ]
      },
      {
        accessLevel: 1,
        name: 'Listings',
        url: '/listings',
        icon: 'reasons',
        permission: permissions.MANAGE_LISTINGS,
      },
      {
        accessLevel: 1,
        name: 'Shop Now',
        url: '/shop-now',
        icon: 'shop-now',
        permission: permissions.MANAGE_SHOP_NOW,
        isVisible: ({ getters }) => {
          return getters.getSetting('SHOP_NOW_ENABLED');
        },
      },
      {
        accessLevel: 1,
        name: 'Advanced Exchanges',
        url: '/advanced-exchanges',
        icon: 'exchange',
        permission: permissions.MANAGE_ADVANCED_EXCHANGES,
        isVisible: ({ getters }) => {
          return getters.getSetting('ADVANCED_EXCHANGES_ENABLED');
        },
      },
      {
        accessLevel: 1,
        name: 'Settings',
        icon: 'settings',
        url: '/settings',
        items: [
          {
            accessLevel: 1,
            name: 'All',
            url: '/settings'
          },
          {
            accessLevel: 1,
            name: 'General',
            url: '/settings/general',
            permission: permissions.MANAGE_GENERAL_SETTINGS,
          },
          {
            accessLevel: 1,
            name: 'Return policies',
            url: '/settings/return-policy',
            permission: permissions.MANAGE_RETURN_POLICIES,
          },
          {
            accessLevel: 1,
            name: 'Warranty policies',
            url: '/settings/warranty-policy',
            permission: permissions.MANAGE_WARRANTIES,
            isVisible: ({ getters }) => {
              return getters.getSetting('WARRANTIES_ENABLED');
            },
          },
          {
            accessLevel: 1,
            name: 'Workflows',
            url: '/workflows',
            permission: permissions.MANAGE_WORKFLOWS,
          },
          {
            accessLevel: 1,
            name: 'Destinations',
            url: '/settings/destinations',
            permission: permissions.MANAGE_DESTINATIONS,
          },
          {
            accessLevel: 1,
            name: 'Shipping',
            url: '/settings/shipping',
            permission: permissions.MANAGE_SHIPPING_SERVICES,
          },
          {
            accessLevel: 1,
            name: 'Notifications',
            url: '/settings/notifications',
            permission: permissions.MANAGE_NOTIFICATIONS,
          },
          {
            accessLevel: 1,
            name: 'Reasons',
            url: '/settings/reasons',
            permission: permissions.MANAGE_REASONS,
          },
          {
            accessLevel: 1,
            name: 'Customizations',
            url: '/settings/customizations',
            permission: permissions.MANAGE_CUSTOMIZATIONS,
          },
          {
            accessLevel: 1,
            name: 'Billing',
            url: '/settings/billing',
            billingPermission: true
          },
          {
            accessLevel: 1,
            name: 'Account',
            url: '/settings/account',
            permission: permissions.VIEW_USERS,
          },
          {
            accessLevel: 2,
            name: 'Developers',
            url: '/settings/developers',
            permission: permissions.MANAGE_DEVELOPER_TOOLS,
            isVisible: ({ getters }) => {
              return getters.getSetting('DEVELOPER_TOOLS_ENABLED');
            },
          },
          {
            accessLevel: 1,
            name: 'Bundles',
            url: '/settings/bundles',
            permission: permissions.MANAGE_BUNDLES,
            isVisible: ({ getters }) => {
              return getters.getSetting('BUNDLES_ENABLED') && getters.getSetting('BUNDLE_PROVIDER') === 'loop';
            },
          },
          {
            accessLevel: 1,
            name: 'Integrations',
            url: '/settings/integrations',
            permission: permissions.MANAGE_INTEGRATIONS,
          },
          {
            accessLevel: 2,
            name: 'Point of Sale',
            url: '/settings/point-of-sale',
            isVisible: ({ rootGetters }) => (rootGetters.getSetting('ENABLE_POINT_OF_SALE')),
            permission: permissions.MANAGE_POINT_OF_SALE,
          },
          {
            accessLevel: 2,
            name: 'Tracking',
            url: '/settings/tracking',
            isVisible: ({ getters }) => {
              return getters.getSetting('RETURN_TRACKING_AVAILABLE');
            },
            permission: permissions.MANAGE_TRACKING,
          },
          {
            accessLevel: 2,
            name: 'Policy Rules',
            url: '/settings/rules',
            permission: permissions.MANAGE_POLICY_RULES,
          },
        ]
      },
    ],
    secondaryItems: [
      {
        accessLevel: 1,
        name: 'Discover',
        url: '/discover',
        icon: 'sparkle',
      },
      {
        accessLevel: 1,
        name: 'Onboarding Hub',
        url: '/onboarding-hub',
        icon: 'check-list',
        isVisible: ({ rootGetters }) => {
          return rootGetters.user.has_onboarding_modules;
        },
      },
      {
        accessLevel: 1,
        external: true,
        name: 'Release Notes',
        url: 'https://help.loopreturns.com/category/235-release-notes',
        icon: 'note',
        newWindow: true,
        badge: 'New',
        trackEvent: true,
      },
      {
        external: true,
        name: 'Returns Portal',
        url: ({ rootGetters }) => rootGetters['customerPortalLink'],
        icon: 'link',
        newWindow: true
      },
      {
        external: true,
        name: 'Help Center',
        url: 'http://help.loopreturns.com',
        icon: 'help',
        newWindow: true
      }
    ],
    accountItems: [
      {
        accessLevel: 1,
        name: 'Preferences',
        url: '/preferences',
      },
      {
        accessLevel: 1,
        external: true,
        name: 'Release Notes',
        url: 'https://help.loopreturns.com/category/235-release-notes',
        newWindow: true,
      },
      {
        external: true,
        name: 'Returns Portal',
        url: ({ rootGetters }) => rootGetters['customerPortalLink'],
        newWindow: true
      },
      {
        external: true,
        name: 'Help Center',
        url: 'http://help.loopreturns.com',
        newWindow: true
      },
      {
        external: true,
        name: 'Status Page',
        url: 'https://status.loopreturns.com/',
        newWindow: true
      },
      {
        name: 'Log out',
        url: '/logout'
      }
    ]
  },
  getters: {
    allowedItems(state, getters, rootState, rootGetters) {
      if (rootState.userData && rootState.userData.shop) {
        const permissionsData = {
          userLevel: +rootState.userData.shop.plan.access_level,
          billingPermission: +rootState.userData.billing_permission,
          settings: rootState.userData.shop.settings,
          permissions: rootState.userData.permissions,
          userRoles: rootState.userData.roles
        };
        const properties = {
          state,
          getters: {
            getSetting: rootGetters.getSetting,
            hasPermission: rootGetters.hasPermission,
          },
          rootState,
          rootGetters,
          data: permissionsData
        };

        return state.items
          .map(item => {
            // Check permissions on sub items
            if (item.items && item.items.length) {
              const cloned = cloneDeep(item);
              cloned.items = item.items
                .map((subItem) => {
                  return evaluateProperties(subItem, properties);
                })
                .filter((subItem) => {
                  return checkPermissions(permissionsData, cloneDeep(subItem));
                });

              // Recheck to see if we should just hide the top level nav
              if (!cloned.items.length) {
                cloned.hidden = true;
              }

              return evaluateProperties(cloned, properties);
            }

            return evaluateProperties(item, properties);
          })
          .filter(item => {
            return checkPermissions(permissionsData, cloneDeep(item));
          });
      }

      return [];
    },
    secondaryItems(state, getters, rootState, rootGetters) {
      const context = { state, getters, rootState, rootGetters };
      return state.secondaryItems.map(item => {
        return Object.keys(item).reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: isFunction(item[cur]) ? item[cur](context) : item[cur]
          };
        }, {});
      }).filter((item) => typeof item.isVisible === 'undefined' || item.isVisible);
    },
    accountItems(state, getters, rootState, rootGetters) {
      const context = { state, getters, rootState, rootGetters };
      return state.accountItems.map((item) => {
        return Object.keys(item).reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: isFunction(item[cur]) ? item[cur](context) : item[cur]
          };
        }, {});
      }).filter((item) => typeof item.isVisible === 'undefined' || !item.isVisible === false);
    }
  }
};

export default navigation;
