import _ from 'lodash';
import request from '@/util/api/customizations';
import Auth from '@/util/api/auth';
import en from '@/schemas/content/en.json';
import theme from '@/schemas/theme/default.json';
import { removeDefaults } from '@/util/helpers/customizations';
import { track } from '@/util/helpers/segment';
import { trackingEventProperties, trackingEvents } from '@/constants/segment';

export default {
  namespaced: true,
  state: {
    customizations: {
      content: {},
      theme: {}
    },
    newCustomizations: {
      content: {},
      theme: {}
    },
    saving: false,
    content: {}
  },
  mutations: {
    update(state, { type, payload }) {
      state.newCustomizations[type] = payload;
    },
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setContent(state, content) {
      state.content = content;
    }
  },
  actions: {
    getPageData({ commit }/* , route */) {
      return request.get()
        .then(res => {
          commit('setProperty', {
            key: 'customizations',
            value: {
              content: res.customizations.content.en || {},
              theme: res.customizations.theme || {}
            }
          });
        });
    },
    async getContentData({ commit }/* , route */) {
      const [user, content] = await Promise.all([
        Auth.getCurrentUser(),
        request.get()
      ]);
      commit('setContent', content.customizations.content);
      commit('setUser', user.data, { root: true });
      commit('setProperty', {
        key: 'customizations',
        value: {
          content: content.customizations.content.en || {},
          theme: content.customizations.theme || {}
        }
      });
      return Promise.resolve(content.customizations.content);
    },
    async save({ commit, getters }) {
      // Set saving state, this lets us update the page to show spinners, etc
      commit('setProperty', { key: 'saving', value: true });
      let status = 'success';
      try {
        const res = await request.set(getters.updatedCustomizations);

        // Add any data we've received back to the store
        commit('setProperty', {
          key: 'customizations',
          value: {
            content: res.content || {},
            theme: res.theme || {}
          }
        });

        // We've received an error from the api
        if (res.errors) {
          throw new Error(res.errors);
        }

        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          message: 'Customization saved successfully',
          type: 'success',
        }, { root: true });
        return Promise.resolve(true);
      } catch (error) {
        console.error(error);
        status = 'error';

        commit('setProperty', { key: 'saving', value: false });
        commit('setToast', {
          message: `Whoops, we couldn't save this`,
          type: 'error',
        }, { root: true });
        return Promise.resolve(false);
      } finally {
        track(trackingEvents.SAVE_BAR_SAVE_CLICKED, {
          page: 'Customizations',
          status,
          version: trackingEventProperties.ADMIN_NAV_UPDATED_V1,
        });
      }
    }
  },
  getters: {
    content(state) {
      return _.mergeWith(
        {},
        en,
        state.customizations.content,
        (obj, src) => {
          if (_.isArray(obj)) {
            return src;
          }
        }
      );
    },
    theme(state) {
      return _.merge(
        {},
        theme,
        state.customizations.theme
      );
    },
    updatedCustomizations(state) {
      return {
        content: removeDefaults(state.newCustomizations.content, en),
        theme: removeDefaults(state.newCustomizations.theme, theme)
      };
    },
    hasChanges(state, getters) {
      return !_.isEqual(state.customizations, getters.updatedCustomizations);
    },
  }
};
