import { createStore } from 'vuex';
import stateData from './stateData';
import provinceData from './provinceData';
import modules from './modules';
import env from '@/env';

const toBoolean = (value) => {
  // If is yes/no, cast to boolean
  if (typeof value === 'string' && ['yes', 'no'].includes(value)) {
    return value === 'yes';
  }

  return value;
};

const store = createStore({
  state: {
    userData: null,
    token: null,
    layout: 'app',
    layoutType: 'normal',
    loader: false,
    ignoreLoader: false,
    toast: {
      message: '',
      type: '',
    },
    stateData,
    provinceData,
    ssoError: null,
    installing: false,
    oauthError: null,
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setToast(state, { message, type, duration }) {
      state.toast.message = message;
      state.toast.type = type;
      state.toast.duration = duration;
    },
    setLoader(state, loader) {
      state.loader = loader;
    },
    setLayout(state, layout) {
      state.layout = layout;
    },
    setLayoutType(state, type) {
      state.layoutType = type;
    },
    setUser(state, user) {
      state.userData = user;
    },
    setSettings(state, settings) {
      if (state.userData && state.userData.shop && state.userData.shop.settings) {
        state.userData.shop.settings = settings;
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setSSOError(state, error) {
      state.ssoError = error;
    },
    setOauthError(state, error) {
      state.oauthError = error;
    },
    // Mutation to update the pivot value of a setting
    updateSettingPivotValue(state, { key, value }) {
      const setting = state.userData.shop.settings.find(s => s.key === key);

      if (setting) {
        setting.pivot.value = value;
      }
    },
  },
  actions: {
    setLayout({ commit }, routeTo) {
      return new Promise(resolve => {
        commit('setLayout', routeTo.meta.layout);
        resolve();
      });
    },
    setSetting({ commit, state }, { key, value }) {
      if (state.userData && state.userData.shop && state.userData.shop.settings) {
        const isExistingSetting = state.userData.shop.settings.some(item => item.key === key);
        let newSettings = state.userData.shop.settings;
        if (isExistingSetting) {
          // Dispatch the updateSettingPivotValue mutation
          commit('updateSettingPivotValue', { key, value });
        } else {
          newSettings = [
            ...state.userData.shop.settings,
            {
              key,
              pivot: {
                value
              }
            }
          ];
        }

        commit('setSettings', newSettings);
        return true;
      }

      return false;
    },
    setToast: ({ commit }, toast) => {
      commit('setToast', {
        message: toast.message,
        type: toast.type,
        duration: toast.duration,
      });
    },
  },
  getters: {
    shopProviderDomain(state) {
      return state?.userData?.shop?.provider_domain ?? null;
    },
    shop(state) {
      return state?.userData?.shop ?? null;
    },
    customerPortalLink(state) {
      if (state && state.userData && state.userData.shop) {
        const envUrl = env('VITE_URL') || 'loopreturns.com';
        const { custom_domain, subdomain } = state.userData.shop;
        return custom_domain ? `https://${custom_domain}` : `https://${subdomain}.${envUrl}`;
      }

      return '';
    },
    userLevel(state) {
      if (state.userData && state.userData.shop && state.userData.shop.plan) {
        return +state.userData.shop.plan.access_level;
      }

      return 1;
    },
    settings(state) {
      // Create an object of settings so we can access settings by key
      if (state.userData?.shop?.settings) {
        return state.userData.shop.settings.reduce((accumulator, current) => {
          return {
            ...accumulator,
            [current.key]: {
              data: current,
              value: toBoolean(current.pivot.value)
            }
          };
        }, {});
      }
      return {};
    },
    features(state) {
      // Create an object of features so we can access features by key
      if (state.userData?.shop?.features) {
        return state.userData.shop.features.reduce((accumulator, current) => {
          return {
            ...accumulator,
            [current.slug]: current
          };
        }, {});
      }
      return {};
    },
    // !!! DEPRECATED
    // !!! Use getSettingValue instead!
    getSetting: (state, getters) => (setting) => {
      return getters.settings[setting] ? getters.settings[setting].value : null;
    },
    /**
     * @param {string} setting - The setting name, ie NEW_ORDER_PREFIX
     * @returns {Promise} - Resolves with the setting value or null if it doesn't exist
     */
    getSettingValue: (state, getters) => async (setting) => {
      return new Promise((resolve) => {
        const interval = () => {
          // We have data
          if (state.userData?.shop?.settings) {
            return resolve(getters.settings[setting]?.value ?? null);
          }

          // We don't have data, wait and try again
          setTimeout(interval, 100);
        };

        interval();
      });
    },
    /**
     * @param {string} setting - The setting name, ie NEW_ORDER_PREFIX
     * @returns {Promise} - Resolves with the setting id or null if it doesn't exist
     */
    getSettingId: (state, getters) => (setting) => {
      return new Promise((resolve) => {
        const interval = () => {
          // We have data
          if (state.userData?.shop?.settings) {
            return resolve(getters.settings[setting]?.data?.id ?? null);
          }

          // We don't have data, wait and try again
          setTimeout(interval, 100);
        };

        interval();
      });
    },
    /**
     * Get custom feature data
     * @param {string} slug - The feature slug, ie keep-item
     * @returns {Promise} - Resolves with the feature object or null if it's not active
     */
    getFeature: (state) => (slug) => {
      return new Promise((resolve) => {
        const interval = () => {
          // We have data
          if (state.userData?.shop?.features) {
            const feature = state.userData.shop.features.find(feature => feature.slug === slug);
            return resolve(feature ?? null);
          }

          // We don't have data, wait and try again
          setTimeout(interval, 100);
        };

        interval();
      });
    },
    /**
     * Get service data
     * @param {string} name - The service name
     * @returns {Promise} - Resolves with the feature object or null if it's not active
     */
    getService: (state) => (name) => {
      return new Promise((resolve) => {
        const interval = () => {
          // We have data
          if (state.userData?.shop?.services) {
            const service = state.userData.shop.services.find((service) => service.name === name);
            return resolve(service ?? null);
          }

          // We don't have data, wait and try again
          setTimeout(interval, 100);
        };

        interval();
      });
    },
    available(state, getters) {
      // An aggregate of all features and whether they're available to the current
      // user or not. Eventually this should be replaced/refactored to use whatever new
      // system we come up with for unifying our feature flags
      const level = getters.userLevel;

      return {
        returns: true,
        reporting: true,
        listings: true,
        policyRules: level >= 2,
        shopNow: getters.getSetting('SHOP_NOW_ENABLED'),
        globalSettings: true,
        returnPolicy: true,
        destinations: true,
        notifications: true,
        reasons: true,
        customizations: true,
        billing: !!state.userData?.billing_permission,
        account: true,
        shippingPage: true,
        onStore: getters.getSetting('ON_STORE_API_ENABLED'),
        instantReturns: getters.getSetting('INSTANT_RETURN_ENABLED'),
        stripe: state.userData?.shop?.services?.some((service) => {
          // We should be using type: 'integration' moving forward, leaving both type: 'stripe' and 'integration' for now
          return (service.type === 'stripe' || service.type === 'integration') && service.state === 'active';
        })
      };
    },
    /**
     * Determines whether or not a feature flag is active
     * Will return false if it can't find the feature flag
     * @param {string} flagId - The feature flag's id, set when creating a feature flag in the back end
     * @returns {Boolean}
     */
    hasFeature: (state) => (flagId) => {
      const flags = state.userData?.feature_flags ?? [];
      const flag = flags.find((flag) => flag.id === flagId);
      return flag?.active ?? false;
    },
    hasPermission: (state) => (permission) => {
      const permissions = state.userData?.permissions ?? [];
      return permissions.includes(permission);
    },
    hasRole: state => role => {
      const roles = state.userData?.roles ?? [];
      return roles.includes(role);
    },
    isShopifyProvider: (state) => () => {
      return state.userData?.shop?.provider === 'shopify';
    },
    user: state => state.userData,
    instances: state => state.userData?.sso_instances ?? [],
  },
  modules: {
    ...modules
  },
  strict: env('MODE') !== 'production' && env('MODE') !== 'test',
});

export default store;
