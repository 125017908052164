import axios from 'axios';

const mapRegion = ({ id, name, code, children, parent_id }) => {
  return {
    id,
    label: name,
    countryCode: code,
    parentId: parent_id,
    ...(children?.length ? { children: children.map(mapRegion) } : { })
  };
};

export default {
  getAll(managedShipping = false) {
    return axios.get('/zones', { params: { managedShipping: managedShipping ? 1 : 0 } })
      .then((res) => {
        return res.data.map(mapRegion);
      });
  }
};
