<template>
  <nav class="multi-product-sidebar">
    <div class="flex multi-product-sidebar__container">
      <div
        class="multi-product-sidebar__primary"
        :class="{ 'primary-expanded' : isExpanded }"
      >
        <ul class="multi-product-sidebar__top">
          <li
            v-for="(item, index) in primaryNav"
            :key="index"
            class="multi-product-sidebar__primary--item"
            :class="{
              'primary-active': item.name === primaryNavVisible,
              'disabled': itemIsDisabled(item),
            }"
            tabindex="0"
            role="button"
            @keydown.enter="togglePrimaryMenu(item)"
            @click="togglePrimaryMenu(item)"
          >
            <svg-icon :name="item.bubble && item?.bubble() ? item.iconBubble : item.icon" />
            <base-text
              v-if="!isExpanded"
              class="multi-product-sidebar__primary--item--text"
              type="text-body-semibold"
            >
              {{ item.name }}
            </base-text>
          </li>
        </ul>

        <!--bottom nav items-->
        <ul class="multi-product-sidebar__bottom">
          <li
            v-for="(item, index) in bottomNav"
            :key="index"
          >
            <a
              v-if="item.isExternal"
              class="multi-product-sidebar__primary--item"
              :href="resolveRoute(item)"
              target="_blank"
            >
              <svg-icon :name="item.icon" />
              <base-text
                v-if="!isExpanded"
                class="multi-product-sidebar__primary--item--text"
                type="text-body-semibold"
              >
                {{ item.name }}
              </base-text>
            </a>
            <router-link
              v-else
              class="multi-product-sidebar__primary--item"
              :class="{'primary-active': item.name === primaryNavVisible}"
              :to="item.route"
              @click="togglePrimaryMenu(item)"
            >
              <svg-icon :name="item.icon" />
              <base-text
                v-if="!isExpanded"
                class="multi-product-sidebar__primary--item--text"
                type="text-body-semibold"
              >
                {{ item.name }}
              </base-text>
            </router-link>
          </li>
        </ul>
      </div>

      <div
        v-show="isExpanded && secondaryNavItems.length > 0"
        class="menu multi-product-sidebar__secondary"
        :class="{ 'secondary-expanded': isExpanded }"
      >
        <div
          class="menu multi-product-sidebar__secondary-header flex"
          tabindex="0"
          role="button"
          @keydown.enter="closeMenu"
          @click="closeMenu"
        >
          <base-text
            class="multi-product-sidebar__secondary-header--text"
            type="text-h4"
          >
            {{ primaryNavVisible }}
          </base-text>
          <svg-icon
            class="multi-product-sidebar__secondary-header--close"
            name="chevron-left"
          />
        </div>
        <div
          v-for="(item, index) in secondaryNavItems"
          :key="index"
          class="multi-product-sidebar__secondary--item"
          :class="[ item.children ? 'multi-product-sidebar__expandable-nav--item' : 'multi-product-sidebar__flat-nav--item', { 'disabled': itemIsDisabled(item)} ]"
          @keydown.enter="toggleSecondaryMenu(item)"
          @click="toggleSecondaryMenu(item)"
        >
          <svg-icon
            v-if="!item.children"
            class="multi-product-sidebar__flat-nav--item--icon"
            :class="{ 'secondary-active': item.name === secondaryNavVisible }"
            name="bar"
          />
          <div
            tabindex="0"
            role="button"
            :class="[ item.children ? 'multi-product-sidebar__expandable-nav--item--content' : 'multi-product-sidebar__flat-nav--item--content', { 'secondary-active': item.name === secondaryNavVisible } ]"
          >
            <base-text
              :class="[ item.children ? 'multi-product-sidebar__expandable-nav--item--text' : 'multi-product-sidebar__flat-nav--item--text' ]"
              type="text-body-semibold"
            >
              {{ item.name }}
            </base-text>
            <svg-icon
              v-if="item?.children?.length > 0"
              :name="`chevron-${item.name === secondaryNavVisible ? 'up' : 'down'}`"
            />
          </div>
          <transition-expand>
            <div
              v-if="secondaryNavVisible === item.name && secondaryNavItems.length > 0"
              class="multi-product-sidebar__tertiary"
            >
              <div
                v-for="(item, index) in tertiaryNavItems"
                :key="index"
                class="multi-product-sidebar__tertiary--item multi-product-sidebar__flat-nav--item"
                :class="{ 'disabled': itemIsDisabled(item) }"
                tabindex="0"
                role="button"
                @keydown.enter.stop="selectTertiaryItem(item)"
                @click.stop="selectTertiaryItem(item)"
              >
                <svg-icon
                  class="multi-product-sidebar__flat-nav--item--icon"
                  :class="{ 'tertiary-active': item.name === tertiaryNavVisible }"
                  name="bar"
                />
                <base-text
                  class="multi-product-sidebar__flat-nav--item--text"
                  type="text-body-semibold"
                >
                  {{ item.name }}
                </base-text>
              </div>
            </div>
          </transition-expand>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { SvgIcon, BaseText } from '@loophq/ui';
import { primaryNavItems, bottomNavItems } from '@/util/schemas/multiProductNavigation';
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { featureFlags } from '@/util/schemas/featureFlags';
import { useStore } from 'vuex';
import { permissions } from '@/util/schemas/permissions';

const store = useStore();
const router = useRouter();
const route = useRoute();

const isExpanded = ref(true);
const primaryNavVisible = ref('Returns management');
const secondaryNavVisible = ref('');
const tertiaryNavVisible = ref('');

const emit = defineEmits(['toggle-menu']);

const primaryNav = computed(() => filterVisibleItems(primaryNavItems));
const bottomNav = computed(() => filterVisibleItems(bottomNavItems));

const getActiveNavItem = () => {
  const routePath = window.location.pathname.endsWith('/')
    ? window.location.pathname.slice(0, -1)
    : window.location.pathname;

  //default nav items
  if (routePath === '' || routePath === '/' || routePath === '/home' || routePath === '/login' || routePath === '/oauth/callback') {
    if (store.getters.hasFeature(featureFlags.MISSION_CONTROL_ENABLED)
      && store.getters.hasPermission(permissions.MANAGE_RETURNS)
      && store.getters.hasPermission(permissions.VIEW_ANALYTICS)) {
      primaryNavVisible.value = primaryNavItems[0]?.name;
      isExpanded.value = false;
      emit('toggle-menu', isExpanded.value);
    } else {
      secondaryNavVisible.value = secondaryNavItems.value[0]?.name;
      tertiaryNavVisible.value = tertiaryNavItems.value[0]?.name;
    }
    return;
  }

  //find the nav item that matches the current route and set it as the visible item
  primaryNav.value.forEach(item => {
    const itemUrl = typeof item.url === 'function' ? item.url() : item.url;
    if (routePath.includes(itemUrl)) {
      primaryNavVisible.value = item.name;
      if (item.name === 'Point of Sale' || item.name === 'Home') {
        isExpanded.value = false;
        emit('toggle-menu', isExpanded.value);
      }
    } else {
      if (item.children) {
        item.children.forEach(child => {
          const childUrl = typeof child.url === 'function' ? child.url() : child.url;

          if (routePath.includes(childUrl)) {
            primaryNavVisible.value = item.name;
            secondaryNavVisible.value = child.name;
            isExpanded.value = true;
            emit('toggle-menu', isExpanded.value);
          } else {
            if (child.children) {
              child.children.forEach(grandchild => {

                if (routePath.includes(grandchild.url)) {
                  primaryNavVisible.value = item.name;
                  secondaryNavVisible.value = child.name;
                  tertiaryNavVisible.value = grandchild.name;
                  isExpanded.value = true;
                  emit('toggle-menu', isExpanded.value);
                }
              });
            }
          }
        });
      }
    }
  });

  if (secondaryNavVisible.value === '') {
    bottomNav.value.forEach(item => {
      if (item.url === routePath) {
        primaryNavVisible.value = item.name;
        isExpanded.value = false;
        emit('toggle-menu', isExpanded.value);
      }
    });
  }
};

onMounted(() => {
  if (window.innerWidth > 960) {
    isExpanded.value = true;
    emit('toggle-menu', isExpanded.value);
  }
});

watch(route,() => {
  getActiveNavItem();
});

const togglePrimaryMenu = (item) => {
  primaryNavVisible.value = item.name;

  if (secondaryNavItems.value.length === 0) {
    isExpanded.value = false;
    secondaryNavVisible.value = '';
    tertiaryNavVisible.value = '';
  } else {
    isExpanded.value = true;
    secondaryNavVisible.value = secondaryNavItems.value[0].name;

    if (tertiaryNavItems.value.length > 0) {
      tertiaryNavVisible.value = tertiaryNavItems.value[0].name;
    }
  }

  router.push(item.route);

  emit('toggle-menu', isExpanded.value);
};

const closeMenu = () => {
  isExpanded.value = false;
  emit('toggle-menu', isExpanded.value);
};

const secondaryNavItems = computed(() => {
  const primaryItem = primaryNav.value.find(item => item.name === primaryNavVisible.value);

  return primaryItem && primaryItem.children ?
    primaryItem.children?.filter(item => {
      let isVisible = true;
      if (item.isVisible) {
        isVisible = typeof item.isVisible === 'function' ? item.isVisible() : item.isVisible;
      }
      return isVisible;
    }) : [];
});

const toggleSecondaryMenu = (item) => {
  if (item.name === secondaryNavVisible.value) {
    secondaryNavVisible.value = '';
    return;
  }

  secondaryNavVisible.value = item.name;

  if (tertiaryNavItems.value.length === 0) {
    tertiaryNavVisible.value = '';

    router.push(item.route);
  } else {
    const tertiaryItem = tertiaryNavItems.value[0];
    tertiaryNavVisible.value = tertiaryItem.name;

    router.push(tertiaryItem.route);
  }
};

const tertiaryNavItems = computed(() => {
  const secondaryItem = secondaryNavItems.value.find(item => item.name === secondaryNavVisible.value);

  return secondaryItem && secondaryItem.children ?
    secondaryItem.children.filter(item => {
      let isVisible = true;
      if (item.isVisible) {
        isVisible = typeof item.isVisible === 'function' ? item.isVisible() : item.isVisible;
      }
      return isVisible;
    }) : [];
});

const selectTertiaryItem = (item) => {
  tertiaryNavVisible.value = item.name;
  router.push(item.route);
};

const resolveRoute = (item) => {
  if (item.route && typeof item.route === 'function') {
    return item.route();
  }
  return item.route;
};

const itemIsDisabled = (item) => {
  return !!item.isDisabled && typeof item.isDisabled === 'function' && item.isDisabled();
};

const filterVisibleItems = (items) => {
  return items.filter(item => {
    if ('isVisible' in item) {
      return typeof item.isVisible === 'function' ? item.isVisible() : item.isVisible;
    }

    return true;
  });
};

</script>


<style lang="scss" scoped>
.multi-product-sidebar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  top: 72px;
  left: 0;
  background: var(--color-nav-background);
  z-index: 900;

  &__container {
    min-height: calc(100vh - 72px);
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }

  &__primary {
    max-width: 276px;
    width: 276px;
    color: white;
    padding: var(--spacing-16);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.primary-expanded {
      max-width: 70px;
      width: 70px;
    }

    &--item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--spacing-8);
      gap: var(--spacing-12);
      color: var(--color-grey-200);
      cursor: pointer;
      border-radius: 8px;

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:hover {
        background: rgba(255, 255, 255, 5%);
        transition: background var(--transition-300);
      }

      &.focus-visible,
      &:focus-visible {
        outline: unset;
        border: 1px solid white;
        border-radius: 8px;
      }

      &--text {
        color: var(--color-grey-200) !important;
      }

      &.primary-expanded {
        justify-content: center;
      }

      &.primary-active {
        background: rgba(255, 255, 255, 8%);
        color: white;

        &:hover {
          background: rgba(255, 255, 255, 10%);
        }
      }
    }
  }

  &__secondary {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    padding: 1rem;
    background: linear-gradient(90deg, #1e1f22 0%, #212226 100%), var(--nav-background, #212226);
    width: 0;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: var(--spacing-8);
      margin-bottom: var(--spacing-16);
      cursor: pointer;

      &.focus-visible {
        border: 1px solid white;
        border-radius: 8px;
      }

      &--text {
        color: var(--color-grey-200) !important;
      }

      &--close {
        color: white;
      }
    }

    &.secondary-expanded {
      width: 278px;
    }

    &--item {
      color: white;
    }
  }

  &__tertiary {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    margin: var(--spacing-8) 0;
  }

  &__expandable-nav--item {
    color: white;

    &--content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--color-grey-200);
      padding: var(--spacing-8);
      gap: var(--spacing-12);
      border-radius: 8px;
      cursor: pointer;

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:hover {
        background: rgba(255, 255, 255, 5%);
        transition: background var(--transition-300);
      }

      &.secondary-active {
        border-radius: 8px;
        background: rgba(255, 255, 255, 8%);
        color: white;

        &:hover {
          background: rgba(255, 255, 255, 10%);
          transition: background var(--transition-300);
        }
      }

      &.focus-visible {
        border: 1px solid white;
        border-radius: 8px;
      }
    }

    &--text {
      color: var(--color-grey-200) !important;
    }
  }

  &__flat-nav--item {
    display: flex;
    align-items: center;
    padding: var(--spacing-8);
    gap: var(--spacing-12);
    border-radius: 8px;
    cursor: pointer;

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: not-allowed;
    }

    &:hover {
      background: rgba(255, 255, 255, 5%);
      transition: background var(--transition-300);
    }

    &.focus-visible {
      border: 1px solid white;
      border-radius: 8px;
    }

    &--text {
      color: #b6bac3 !important;
    }

    &--icon {
      visibility: hidden;

      &.tertiary-active,
      &.secondary-active {
        color: var(--color-grey-200);
        visibility: visible;
      }
    }
  }

  &.is-expanded {
    width: 346px;
  }

  @media (width <= 1024px) {
    position: absolute;
    z-index: 99;
  }
}
</style>
