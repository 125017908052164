export const shopSettings = {
  CUSTOMIZED_CARRIER_SERVICE_TYPES: 'CUSTOMIZED_CARRIER_SERVICE_TYPES',
  LINE_ITEM_GRADING_AND_DISPOSITION: 'LINE_ITEM_GRADING_AND_DISPOSITION',
  GENERATE_PITNEY_BOWES_QR_CODES: 'GENERATE_PITNEY_BOWES_QR_CODES',
  ORDER_TRACKING_ALLOWED: 'ORDER_TRACKING_ALLOWED', // Shop setting allowing merchant to use feature
  ORDER_TRACKING_ENABLED: 'ORDER_TRACKING_ENABLED', // Shop setting controlled by the toggle input
  RETURN_TRACKING_AVAILABLE: 'RETURN_TRACKING_AVAILABLE',
  RETURN_TRACKING_ENABLED: 'RETURN_TRACKING_ENABLED',
  EXCHANGE_TRACKING_ENABLED: 'EXCHANGE_TRACKING_ENABLED',
  INTEGRATION_KLAVIYO_ENABLED: 'INTEGRATION_KLAVIYO_ENABLED',
  KLAVIYO_EVENTS_FOR_ORDER_TRACKING: 'KLAVIYO_EVENTS_FOR_ORDER_TRACKING',
  KLAVIYO_API_KEY: 'KLAVIYO_API_KEY',
  ENABLE_POINT_OF_SALE: 'ENABLE_POINT_OF_SALE',
  TRACKING_RECOMMENDATIONS_ENABLED: 'TRACKING_RECOMMENDATIONS_ENABLED',
  TRACKING_RECOMMENDATIONS_PROVIDER: 'TRACKING_RECOMMENDATIONS_PROVIDER',
  TRACKING_RECOMMENDATIONS_SOURCE: 'TRACKING_RECOMMENDATIONS_SOURCE',
  FRAUD_TOOLS: 'FRAUD_TOOLS',
  EMBEDDED_ORDER_TRACKING_ENABLED: 'EMBEDDED_ORDER_TRACKING_ENABLED',
  EMBEDDED_ORDER_TRACKING_URL: 'EMBEDDED_ORDER_TRACKING_URL',
  MANAGED_SHIPPING: 'MANAGED_SHIPPING',
  ENFORCE_PRODUCT_RULES: 'ENFORCE_PRODUCT_RULES',
  DESTINATION_LIMIT: 'DESTINATION_LIMIT',
  REFUND_HANDLING_FEE: 'REFUND_HANDLING_FEE',
  EXCHANGE_HANDLING_FEE: 'EXCHANGE_HANDLING_FEE',
  STORE_CREDIT_HANDLING_FEE: 'STORE_CREDIT_HANDLING_FEE',
  MANAGED_HANDLING_FEES: 'MANAGED_HANDLING_FEES',
  DYNAMIC_FEES_ENABLED: 'DYNAMIC_FEES_ENABLED',
};
