import Locations from '@/util/api/locations';

export default {
  namespaced: true,
  state: {
    locations: []
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    setLocations(state, value) {
      state.locations = value;
    }
  },
  actions: {
    async getAll({ commit }) {
      try {
        const locations = await Locations.getAllAvailable();
        commit('setLocations', locations);
        return Promise.resolve(locations);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
