import axios from 'axios';
import changeCase from 'change-object-case';

changeCase.options = {
  recursive: true,
  arrayRecursive: true,
};

export default {
  getAllAvailable() {
    return axios.get('/locations-with-addresses').then(res => {
      return changeCase.toCamel(res.data);
    });
  },
  shopifyImportLocations(shopId) {
    return axios.get(`/shopify-import-locations/${shopId}`).then(res => {
      return changeCase.toCamel(res.data);
    });
  },
  toggleReturnAcceptance(locationId) {
    return axios.post(`/locations/toggle-return-acceptance`, { location_id: locationId });
  }
};
