import { merge } from 'lodash';
import ReturnsApi from '@/util/api/returns';
import { joinChannel } from '@/util/helpers/websocket';
import router from '@/router';

const request = new ReturnsApi();

const filterUsers = (users, currentUser) => {
  return users.filter((user) => {
    const isCurrentUser = user.id === currentUser.id;
    const isLoop = user.email.includes('@loopreturns.com') || user.email.includes('@xariable.com');
    return !isCurrentUser && !isLoop;
  });
};

export default {
  namespaced: true,
  state: {
    text: {},
    returnData: null,
    usesRestock: false,
    autoRestock: false,
    reasons: {},
    activeUsers: [],
    qcReasons: [],
    editSessionId: '',
    editIndex: 0,
    editState: {},
    editOriginalState: {},
    editLoading: false,
    ixLoading: false,
    refundMethodModalOpen: false,
    refundMethodModalLoading: false,
    editFeeModalOpen: false,
    editFeeModalLoading: false,
    returnUpdatedEventFromCurrentUser: false,
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    updateReturn(state, payload) {
      state.returnData = payload;
    },
    addNote(state, note) {
      state.returnData.notes = [
        note,
        ...state.returnData.notes
      ];
    },
    removeNote(state, { id }) {
      state.returnData.notes = state.returnData.notes
        .filter(note => note.id !== id);
    },
    updateLineItem(state, { id, data }) {
      const lineItems = state.returnData.order_line_items.map(item => {
        if (item.id === id) {
          return merge({}, item, data);
        }

        return item;
      });

      state.returnData = {
        ...state.returnData,
        order_line_items: lineItems
      };
    },
    updateAddress(state, { address }) {
      state.returnData.address = address;
    },
    setActiveUsers(state, users) {
      state.activeUsers = users;
    },
    updateTransaction(state, transaction) {
      const transactionsList = state.returnData.transactions_list.map((item) => {
        return (item.id === transaction.id) ? transaction : item;
      });
      state.returnData = {
        ...state.returnData,
        transactions_list: transactionsList,
        transactions: {
          ...state.returnData.transactions,
          refunds: transactionsList.flatMap(tx => tx.refunds),
        }
      };

      if (state.editState?.transactions_list?.length) {
        state.editState = {
          ...state.editState,
          transactions_list: state.editState.transactions_list.map((item) => {
            if (item.id === transaction.id) {
              return transaction;
            }

            return item;
          }),
        };
      }
    },
    transformIXTransactionForDisplay(state) {
      // If the return has an instant exchange record,
      // update the transaction record for correct display in admin
      const instantExchangeRecord = state.editState.instant_exchange;
      const instantTransaction = state.editState.transactions_list.find((tx) => {
        return ['instant-return', 'instant-exchange', 'instant-refund'].includes(tx.type);
      });

      if (instantTransaction && instantExchangeRecord) {
        const ixTransactionRecord = {};
        if (instantExchangeRecord.auth_amount > 0) {
          ixTransactionRecord.auth = {
            amount: instantExchangeRecord.auth_amount,
            currency: instantExchangeRecord.currency,
            date: instantTransaction.auth.date,
            id: instantTransaction.auth.id,
            state: instantTransaction.auth.state,
          };
        }

        ixTransactionRecord.type = instantTransaction.type;
        ixTransactionRecord.card_info = instantTransaction.card_info;
        ixTransactionRecord.id = instantTransaction.id;
        ixTransactionRecord.lastAction = {
          ...instantTransaction,
          amount: instantExchangeRecord.amount,
          currency: instantExchangeRecord.currency,
        };

        this.commit('returns/detail/updateTransaction', ixTransactionRecord);
      }
    }
  },
  actions: {
    clearReturnData({ commit }) {
      commit('setProperty', { key: 'returnData', value: {} });
      commit('setProperty', { key: 'editSessionId', value: '' });
      commit('setProperty', { key: 'editIndex', value: 0 });
      commit('setProperty', { key: 'editState', value: {} });
      commit('setProperty', { key: 'editOriginalState', value: {} });
    },
    setReturnUpdatedEventFromCurrentUser({ commit }, value) {
      // this is used to suppress return updated events that were initiated by the
      // user who is currently editing a return
      commit('setProperty', { key: 'returnUpdatedEventFromCurrentUser', value });
    },
    setRefundMethodModalOpen({ commit }, value) {
      commit('setProperty', { key: 'refundMethodModalOpen', value });
    },
    setRefundMethodModalLoading({ commit }, value) {
      commit('setProperty', { key: 'refundMethodModalLoading', value });
    },
    setEditFeeModalOpen({ commit }, value) {
      commit('setProperty', { key: 'editFeeModalOpen', value });
    },
    setEditFeeModalLoading({ commit }, value) {
      commit('setProperty', { key: 'editFeeModalLoading', value });
    },
    resetRegenerateLabelStatus({ commit, state }) {
      const { editState } = state;
      const { breakdown } = editState;

      breakdown.label = null;

      const newEditState = {
        ...editState,
        breakdown,
      };

      commit('setProperty', {
        key: 'editState',
        value: newEditState,
      });

      commit('setProperty', {
        key: 'editOriginalState',
        value: newEditState,
      });
    },
    async releaseAuthorization({ commit, state }, { transactionId }) {
      commit('setProperty', { key: 'ixLoading', value: true });

      try {
        const res = await request.transactions.release(state.returnData.id, transactionId);
        commit('updateTransaction', res);
        commit('setProperty', { key: 'ixLoading', value: false });
      } catch (error) {
        console.error(error);
        const toast = error.response?.data?.errors[0] ?? `Whoops, we couldn't release this authorization.`;
        commit('setToast', {
          message: toast,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });
      }
    },
    async chargeAuthorization({ commit, state }, { transactionId }) {
      commit('setProperty', { key: 'ixLoading', value: true });

      try {
        const res = await request.transactions.charge(state.returnData.id, transactionId);
        commit('updateTransaction', res);
        commit('setProperty', { key: 'ixLoading', value: false });
      } catch (error) {
        console.error(error);
        const toast = error.response?.data?.errors[0] ?? `Whoops, we couldn't charge this authorization.`;
        commit('setToast', {
          message: toast,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });
      }
    },
    async refundTransaction({ commit, state }, { transactionId }) {
      commit('setProperty', { key: 'ixLoading', value: true });

      try {
        const res = await request.transactions.refund(state.returnData.id, transactionId);
        commit('updateTransaction', res);
        commit('setProperty', { key: 'ixLoading', value: false });
      } catch (error) {
        console.error(error);
        const toast = error.response.data.errors[0] ?? `Whoops, we couldn't refund this charge.`;
        commit('setToast', {
          message: toast,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });
      }
    },
    // Send an edit intent, applying an edit to an edit session
    async sendEditIntent({ commit, state }, { returnId, name, requestData }) {
      try {
        commit('setProperty', { key: 'editLoading', value: true });
        const update = await request.edit.update(returnId, state.editSessionId, name, requestData);
        commit('setProperty', { key: 'editIndex', value: update.index });
        commit('setProperty', { key: 'editSessionId', value: update.sessionId });
        commit('setProperty', { key: 'editState', value: update });
        commit('setProperty', { key: 'editLoading', value: false });
        Promise.resolve(update);
      } catch (err) {
        console.error(err);

        if (err.response?.data?.error) {
          commit('setToast', {
            message: err.response.data.error,
            type: 'error',
          }, { root: true });
        } else {
          commit('setToast', {
            message: `Whoops, we weren't able to complete this edit.`,
            type: 'error',
          }, { root: true });
        }

        commit('setProperty', { key: 'editLoading', value: false });
        Promise.reject(err);
      }
    },
    // Send an edit store intent, saving all current edits for a session
    async sendEditStore({ commit, state }, { returnId }) {
      try {
        commit('setProperty', { key: 'editLoading', value: true });
        const store = await request.edit.store(returnId, state.editSessionId, state.editState.state, state.editState.breakdown);
        commit('setProperty', { key: 'editSessionId', value: '' });
        commit('setProperty', { key: 'editState', value: store });
        commit('setProperty', { key: 'editLoading', value: false });
        commit('setProperty', { key: 'editOriginalState', value: store });
        Promise.resolve(store);
      } catch (err) {
        console.error(err);
        commit('setProperty', { key: 'editLoading', value: false });
        commit('setToast', {
          message: `Whoops, we weren't able to store your edit.`,
          type: 'error',
        }, { root: true });
        Promise.reject(err);
      }
    },
    // Send an edit cancel intent, removing all edits and the edit session
    async sendEditCancel({ commit, state }, { returnId }) {
      try {
        commit('setProperty', { key: 'editLoading', value: true });
        const cancel = await request.edit.destroy(returnId, state.editSessionId);
        commit('setProperty', { key: 'editIndex', value: 0 });
        commit('setProperty', { key: 'editSessionId', value: '' });
        commit('setProperty', { key: 'editState', value: state.editOriginalState });
        commit('setProperty', { key: 'editLoading', value: false });
        Promise.resolve(cancel);
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to cancel your edit.`,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'editLoading', value: false });
        Promise.reject(err);
      }
    },
    async initializeEditState({ commit }, { returnId }) {
      return await request.edit.initialize(returnId).then(res => {
        commit('setProperty', { key: 'editState', value: res.data });
        commit('setProperty', { key: 'editOriginalState', value: res.data });
      });
    },
    async getPageData({ state, commit, rootGetters }, route) {
      // Connect to the presence channel for this return
      try {
        const currentUser = rootGetters.user;
        joinChannel(`return.${route.params.id}`)
          .here((users) => {
            commit('setActiveUsers', filterUsers(users, currentUser));
          }).joining((user) => {
            commit('setActiveUsers', filterUsers([
              ...state.activeUsers,
              user
            ], currentUser));
          })
          .leaving((user) => {
            commit('setActiveUsers', filterUsers(state.activeUsers.filter((active) => {
              return active.id !== user.id;
            }), currentUser));
          });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Unable to establish socket connection');
      }

      const { id } = route.params;

      const pageLoadRequests = [
        request.get(id)
          .then(res => {
            commit('updateReturn', res.data);
          })
          .catch(err => {
            console.error(err);
            router.push({ name: '404' });
          }),
        request.getPage()
          .then(res => {
            commit('setProperty', { key: 'stateData', value: res.data.states }, { root: true });
            commit('setProperty', { key: 'provinceData', value: res.data.provinces }, { root: true });
            commit('setProperty', { key: 'usesRestock', value: res.data.uses_restock });
            commit('setProperty', { key: 'autoRestock', value: res.data.auto_restock });
          }),
        ...(rootGetters.getSetting('EDIT_RETURN_MERCHANTS') ? [
          request.edit.initialize(id).then(res => {
            commit('setProperty', { key: 'editState', value: res });
            commit('setProperty', { key: 'editOriginalState', value: res });
          })
        ] : [])
      ];

      await Promise.all(pageLoadRequests);

      if (rootGetters.getSetting('EDIT_RETURN_MERCHANTS')) {
        commit('transformIXTransactionForDisplay');
      }
    },
    async rejectLineItem({ commit, state }, { returnId, id }) {
      try {
        const reject = await request.rejectLineItem(returnId, id);
        const orderLineItemStatus = [...(state.returnData.order_line_items.find(item => item.id === id).status)];
        const reviewStatus = orderLineItemStatus.find(item => item.status === 'review') || {};
        const updatedReviewStatus = { ...reviewStatus, status: 'reject' };

        commit('updateLineItem', {
          id,
          data: {
            status: orderLineItemStatus.map((status) => status.id === updatedReviewStatus.id ? updatedReviewStatus : status),
          }
        });
        Promise.resolve(reject);
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to reject this line item.`,
          type: 'error',
        }, { root: true });
        Promise.reject(err);
      }
    },
    async acceptReturn({ commit }, { returnId }) {
      try {
        await request.acceptReturn(returnId);
        const newData = await request.get(returnId);

        commit('setProperty', {
          key: 'returnData',
          value: newData.data
        });

        Promise.resolve(newData);
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to accept this return.`,
          type: 'error',
        }, { root: true });
        Promise.reject(err);
      }
    }
  },
  getters: {
    returnData: state => state.returnData,
    lineItemData: (state) => (lineItemId) => {
      return state.returnData?.order_line_items?.filter(item => item.id === parseInt(lineItemId));
    }
  }
};
