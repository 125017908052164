/*eslint-disable indent */
import WarrantyTemplate from '@/util/schemas/workflows/templates/warranty';
import _ from 'lodash';
import Product from '@/util/api/products';
import Regions from '@/util/api/regions';
import {
  mapProductTypesFromApi,
  mapProductTypesToApi,
  mapRegionsFromApi,
  mapRegionsToApi,
} from '@/util/helpers/warranties';
import WarrantyPolicies from '@/util/api/warrantyPolicies';

export const initialState = {
  basic: {
    name: null,
    workflowGroupId: null,
    allRegions: [],
    allProductTypes: [],
    regions: [],
    originalRegions: [],
    productEligibility: {
      type: 'Product IDs',
      productTypes: [],
      originalProductTypes: [],
      productIds: [],
      productSkus: [],
      productTags: [],
    },
    orderEligibility: {
      timeline: 'All orders',
      before: null,
      after: null,
    },
    warrantyWindow: {
      type: 'Limited warranty policy',
      years: null,
      days: null,
      delayStart: false,
    },
  },
  processing: {
    processingType: 'manual',
    photoUploadEnabled: false,
    photoUploadRequired: false,
    photoUploadLabelText: 'Upload an image for your warranty claim.',
    commentEnabled: false,
    commentRequired: false,
    commentLabelText: 'Share a comment about your warranty claim.',
  },
  outcomes: {
    replaceEnabled: false,
    exchangeEnabled: false,
    advancedExchangeEnabled: false,
    shopNowEnabled: false,
    storeCreditEnabled: false,
    refundEnabled: false,
  },
  shipping: {
    keepItemEnabled: false,
    keepItemProcessingType: 'automatic',
    generateLabelsEnabled: false,
    requireReturnShippingEnabled: false,
    returnDestinations: [],
    handlingFeeEnabled: false,
    handlingFeeType: 'flat-rate',
    handlingFeeFlatRate: 0,
    handlingFeePercentageLabelCost: 0,
    handlingFeePercentageReturnValue: 0,
  },
  reasons: {
    enabled: false,
    reasonGroup: null,
  },
  state: 'loading',
  errors: {},
  touched: false,
};

const untouchableProps = [
  'state',
  'allProductTypes',
  'allRegions',
  'productIds',
  'productSkus',
  'productTags',
  'productTypes',
  'touched',
];

export default {
  namespaced: true,
  state: _.cloneDeep(initialState),
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace] = { ...state[namespace], [key]: value ?? null };
      } else {
        state[key] = value ?? null;
      }

      if (!untouchableProps.includes(key)) {
        state.touched = true;
      }
    },
    setBasicProperty(state, { key, value, namespace }) {
      if (namespace) {
        state.basic[namespace] = { ...state.basic[namespace], [key]: value ?? null };
      } else {
        state.basic[key] = value ?? null;
      }

      if (!untouchableProps.includes(key)) {
        state.touched = true;
      }
    },
    setProperties(state, { properties, namespace }) {
      if (namespace) {
        state[namespace] = { ...state[namespace], ...properties };
      } else {
        Object.keys(properties).forEach(key => {
          if (typeof state[key] === 'object' && !Array.isArray(state[key])) {
            state[key] = { ...state[key], ...properties[key] };
          } else {
            state[key] = properties[key];
          }
        });
      }
    },
    setErrors(state, { key, value }) {
      if (!key) return;
      state.errors[key] = value;
    }
  },
  actions: {
    async getWarranties({ dispatch, commit }) {
      await dispatch('settings/workflowGroups/getGroupsByType', { type: 'warranty' }, { root: true });

      // Populate all product types and all regions
      await Promise.all([
        dispatch('getProductTypes'),
        dispatch('getRegions')
      ]);

      commit('setProperty', { key: 'state', value: 'loaded' });
    },
    async saveToWorkflowGroup({ state, dispatch, commit }) {
      commit('setProperty', { key: 'state', value: 'saving' });

      const mappedProductTypes = mapProductTypesToApi({
        allTypes: state.basic.allProductTypes,
        selectedTypes: state.basic.productEligibility.productTypes,
      });

      const mappedRegions = mapRegionsToApi(state.basic.regions);

      const parameters = {
        'eligibilityProductIds': {
          'value': state.basic.productEligibility.productIds,
          'type': 'array'
        },
        'eligibilityProductSkus': {
          'value': state.basic.productEligibility.productSkus,
          'type': 'array'
        },
        'eligibilityProductTags': {
          'value': state.basic.productEligibility.productTags,
          'type': 'array'
        },
        'eligibilityProductTypes': {
          'value': mappedProductTypes,
          'type': 'array'
        },
        'eligibilityZones': {
          'value': mappedRegions,
          'type': 'array'
        },
        'eligibilityOrderDateRangeStart': {
          'value': state.basic.orderEligibility.after?.toISOString().split('T')[0] ?? null,
          'type': 'string'
        },
        'eligibilityOrderDateRangeEnd': {
          'value': state.basic.orderEligibility.before?.toISOString().split('T')[0] ?? null,
          'type': 'string'
        },
        'delayWarrantyUntilAfterReturnWindow': {
          'value': state.basic.warrantyWindow.delayStart,
          'type': 'boolean'
        },
        'warrantyWindowLength': {
          'value': (() => {
            if (state.basic.warrantyWindow.type === 'Unlimited warranty policy') {
              return 100;
            }
            let days = 0;
            if (state.basic.warrantyWindow.days) {
              days += parseInt(state.basic.warrantyWindow.days);
            }

            if (state.basic.warrantyWindow.years) {
              days += parseInt(state.basic.warrantyWindow.years) * 365;
            }
            return days;
          })(),
          'type': 'float'
        },
        'warrantyWindowUnit': {
          'value': state.basic.warrantyWindow.type === 'Unlimited warranty policy'
            ? 'years'
            : 'days',
          'type': 'string'
        },
        'manualReview': {
          'value': state.processing.processingType === 'manual',
          'type': 'boolean'
        },
        'processingEvent': {
          'value': state.processing.processingType === 'automatic' ? state.processing.processingEvent : null,
          'type': 'string',
        },
        'photoUploadEnabled': {
          'value': state.processing.photoUploadEnabled,
          'type': 'boolean'
        },
        'photoUploadRequired': {
          'value': state.processing.photoUploadRequired,
          'type': 'boolean'
        },
        'photoUploadLabelText': {
          'value': state.processing.photoUploadLabelText,
          'type': 'string'
        },
        'commentEnabled': {
          'value': state.processing.commentEnabled,
          'type': 'boolean'
        },
        'commentRequired': {
          'value': state.processing.commentRequired,
          'type': 'boolean'
        },
        'commentLabelText': {
          'value': state.processing.commentLabelText,
          'type': 'string'
        },
        'replaceEnabled': {
          'value': state.outcomes.replaceEnabled,
          'type': 'boolean'
        },
        'exchangeEnabled': {
          'value': state.outcomes.exchangeEnabled,
          'type': 'boolean'
        },
        'advancedExchangeEnabled': {
          'value': state.outcomes.advancedExchangeEnabled,
          'type': 'boolean'
        },
        'shopNowEnabled': {
          'value': state.outcomes.shopNowEnabled,
          'type': 'boolean'
        },
        'storeCreditEnabled': {
          'value': state.outcomes.storeCreditEnabled,
          'type': 'boolean'
        },
        'refundEnabled': {
          'value': state.outcomes.refundEnabled,
          'type': 'boolean'
        },
        'keepItemEnabled': {
          'value': state.shipping.keepItemEnabled,
          'type': 'boolean'
        },
        'keepItemImmediate': {
            'value': state.shipping.keepItemProcessingType === 'automatic',
            'type': 'boolean'
        },
        'generateLabelsEnabled': {
          'value': state.shipping.generateLabelsEnabled,
          'type': 'boolean'
        },
        'requireReturnShippingEnabled': {
          'value': state.shipping.requireReturnShippingEnabled,
          'type': 'boolean'
        },
        'returnDestinations': {
          'value': state.shipping.returnDestinations,
          'type': 'array'
        },
        'handlingFeeEnabled': {
          'value': state.shipping.handlingFeeEnabled,
          'type': 'boolean'
        },
        'handlingFeeType': {
          'value': (() => {
            switch (state.shipping.handlingFeeType) {
              case 'flat-rate': return 'flat';
              case 'label-cost': return 'percentage';
              case 'return-value': return 'percentage_of_value';
              default: return 'waive';
            }
          })(),
          'type': 'string'
        },
        'handlingFeeApplicationOption': {
          'value': 'override',
          'type': 'string'
        },
        'handlingFeeAmount': {
          'value': (() => {
            switch (state.shipping.handlingFeeType) {
              case 'flat-rate': return state.shipping.handlingFeeFlatRate;
              case 'label-cost': return state.shipping.handlingFeePercentageLabelCost;
              case 'return-value': return state.shipping.handlingFeePercentageReturnValue;
              default: return 0;
            }
          })(),
          'type': 'float'
        },
        'returnReasonGroup': {
          'value': state.reasons.reasonGroup,
          'type': 'integer'
        },
        'returnReasonGroupEnabled': {
          'value': state.reasons.enabled,
          'type': 'boolean'
        }
      };
      Object.keys(parameters).forEach(key => {
        if (parameters[key].value === null
          || parameters[key].value === undefined
          || (Array.isArray(parameters[key].value) && !parameters[key].value.length)
        ) {
          delete parameters[key];
        }
      });

      const conditionNames = [];
      if (state.basic.productEligibility.productIds.length) {
        conditionNames.push('productIds');
      }
      if (state.basic.productEligibility.productSkus.length) {
        conditionNames.push('productSkus');
      }
      if (state.basic.productEligibility.productTags.length) {
        conditionNames.push('productTags');
      }
      if (state.basic.productEligibility.productTypes.length) {
        conditionNames.push('productTypes');
      }
      if (state.basic.regions.length) {
        conditionNames.push('zones');
      }
      if (state.basic.orderEligibility.timeline !== 'All orders' || state.basic.warrantyWindow.delayStart) {
        conditionNames.push('date');
      }

      const actionNames = ['warrantyWindow', 'excludeOutcomes', 'storeCreditIncentives', 'shopNowIncentives'];
      if (state.processing.processingType === 'Manual processing') {
        actionNames.push('manualReview');
      }
      if (state.processing.processingEvent) {
        actionNames.push('processingEvent');
      }
      if (state.shipping.keepItemEnabled) {
        actionNames.push('keepItem');
      }
      if (!state.shipping.generateLabelsEnabled) {
        actionNames.push('disableLabel');
      }
      if (state.shipping.returnDestinations?.length) {
        actionNames.push('destinationOverride');
      }
      if (state.shipping.handlingFeeEnabled) {
        actionNames.push('handlingFee');
      }
      if (state.reasons.enabled) {
        actionNames.push('returnReasons');
      }

      const userInputNames = [];
      if (state.processing.photoUploadEnabled) {
        userInputNames.push('photoUpload');
      }
      if (state.processing.commentEnabled) {
        userInputNames.push('comment');
      }

      const rule = WarrantyTemplate.buildRule(conditionNames, actionNames, userInputNames);

      const name = state.basic.name;

      const draftGroup = {
        name: name,
        type: 'warranty',
        parameters: parameters,
        workflows: [
          {
            name: name,
            description: 'Defines a warranty policy',
            rules: JSON.stringify(rule),
          }
        ],
      };

      if (state.basic.workflowGroupId) {
        draftGroup.id = state.basic.workflowGroupId;
      }

      const warrantyGroup = await dispatch('settings/workflowGroups/setAndSaveDraftWorkflowGroupByType', { group: draftGroup, type: 'warranty' }, { root: true });
      commit('setProperty', { key: 'state', value: 'saved' });
      commit('setBasicProperty', { key: 'workflowGroupId', value: warrantyGroup?.id });
      commit('setProperty', { key: 'touched', value: false });

      return warrantyGroup;
    },
    async mapFromWorkflowGroup({ state, commit }, { group }) {
      commit('setProperty', { key: 'state', value: 'loading' });

      const mappedProductTypes = mapProductTypesFromApi({
        allTypes: state.basic.allProductTypes,
        selectedTypes: group.parameters.eligibilityProductTypes?.value ?? [],
      });

      const mappedRegions = mapRegionsFromApi({
        allRegions: state.basic.allRegions,
        selectedRegions: group.parameters.eligibilityZones?.value ?? [],
      });

      const properties = {
        basic: {
          name: group.name,
          workflowGroupId: group.id,
          regions: mappedRegions,
          originalRegions: group.parameters.eligibilityZones?.value ?? [],
          orderEligibility: {
            timeline: group.parameters.eligibilityOrderDateRangeStart && group.parameters.eligibilityOrderDateRangeEnd
              ? 'Orders placed between'
              : group.parameters.eligibilityOrderDateRangeStart
                ? 'Orders placed after'
                : group.parameters.eligibilityOrderDateRangeEnd
                  ? 'Orders placed before'
                  : 'All orders',
            before: (function() {
              if (group.parameters.eligibilityOrderDateRangeEnd) {
                const date = new Date(group.parameters.eligibilityOrderDateRangeEnd.value);
                return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
              }
              return null;
            })(),
            after: (function() {
              if (group.parameters.eligibilityOrderDateRangeStart) {
                const date = new Date(group.parameters.eligibilityOrderDateRangeStart.value);
                return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
              }
              return null;
            })(),
          },
          warrantyWindow: {
            type: group.parameters.warrantyWindowLength?.value >= 100 && group.parameters.warrantyWindowUnit?.value === 'years'
              ? 'Unlimited warranty policy'
              : 'Limited warranty policy',
            years: (function () {
              const {
                warrantyWindowUnit,
                warrantyWindowLength,
              } = group.parameters;
              if (warrantyWindowUnit?.value === 'years') {
                return group.parameters.warrantyWindowLength?.value ?? 0;
              }
              if (warrantyWindowLength?.value >= 365) {
                return Math.floor(warrantyWindowLength.value / 365);
              }
              return null;
            })(),
            days: (function () {
              const {
                warrantyWindowUnit,
                warrantyWindowLength,
              } = group.parameters;
              if (warrantyWindowUnit?.value === 'years') {
                return null;
              }
              if (warrantyWindowLength?.value >= 365) {
                return Math.floor(warrantyWindowLength.value % 365);
              }
              return group.parameters.warrantyWindowLength?.value ?? 0;
            })(),
            delayStart: group.parameters.delayWarrantyUntilAfterReturnWindow?.value ?? false,
          },
          productEligibility: {
            type: group.parameters.eligibilityProductIds?.value?.length
              ? 'Product IDs'
              : group.parameters.eligibilityProductSkus?.value?.length
                ? 'Product SKUs'
                : group.parameters.eligibilityProductTags?.value?.length
                  ? 'Product tags'
                  : 'Product types',
            productTypes: mappedProductTypes,
            originalProductTypes: group.parameters.eligibilityOriginalProductTypes?.value ?? [],
            productIds: group.parameters.eligibilityProductIds?.value ?? [],
            productSkus: group.parameters.eligibilityProductSkus?.value ?? [],
            productTags: group.parameters.eligibilityProductTags?.value ?? [],
          },
        },
        processing: {
          processingType: group.parameters.manualReview?.value ?? true
            ? 'manual'
            : 'automatic',
          processingEvent: group.parameters.processingEvent?.value ?? 'immediate',
          photoUploadEnabled: group.parameters.photoUploadEnabled?.value ?? false,
          photoUploadRequired: group.parameters.photoUploadRequired?.value ?? false,
          photoUploadLabelText: group.parameters.photoUploadLabelText?.value ?? 'Upload an image for your warranty claim.',
          commentEnabled: group.parameters.commentEnabled?.value ?? false,
          commentRequired: group.parameters.commentRequired?.value ?? false,
          commentLabelText: group.parameters.commentLabelText?.value ?? 'Share a comment about your warranty claim.',
        },
        outcomes: {
          replaceEnabled: group.parameters.replaceEnabled?.value ?? false,
          exchangeEnabled: group.parameters.exchangeEnabled?.value ?? false,
          advancedExchangeEnabled: group.parameters.advancedExchangeEnabled?.value ?? false,
          shopNowEnabled: group.parameters.shopNowEnabled?.value ?? false,
          storeCreditEnabled: group.parameters.storeCreditEnabled?.value ?? false,
          refundEnabled: group.parameters.refundEnabled?.value ?? false,
        },
        shipping: {
          keepItemEnabled: group.parameters.keepItemEnabled?.value ?? true,
          keepItemProcessingType: group.parameters.keepItemImmediate?.value ?? true
            ? 'automatic'
            : 'manual',
          requireReturnShippingEnabled: group.parameters.requireReturnShippingEnabled?.value ?? true,
          generateLabelsEnabled: group.parameters.generateLabelsEnabled?.value ?? true,
          returnDestinations: group.parameters.returnDestinations?.value ?? [],
          handlingFeeEnabled: group.parameters.handlingFeeEnabled?.value ?? false,
          handlingFeeType: (() => {
            switch (group.parameters.handlingFeeType?.value) {
              case 'flat': return 'flat-rate';
              case 'percentage': return 'label-cost';
              case 'percentage_of_value': return 'return-value';
              default: return 'waive';
            }
          })(),
          handlingFeeFlatRate: group.parameters.handlingFeeType?.value === 'flat'
            ? group.parameters.handlingFeeAmount?.value ?? 0
            : 0,
          handlingFeePercentageLabelCost: group.parameters.handlingFeeType?.value === 'percentage'
            ? group.parameters.handlingFeeAmount?.value ?? 0
            : 0,
          handlingFeePercentageReturnValue: group.parameters.handlingFeeType?.value === 'percentage_of_value'
            ? group.parameters.handlingFeeAmount?.value ?? 0
            : 0,
        },
        reasons: {
          enabled: group.parameters.returnReasonGroupEnabled?.value ? true : false,
          reasonGroup: group.parameters.returnReasonGroup?.value,
        },
        state: 'loaded',
      };

      commit('setProperties', { properties });
    },
    reset({ commit }, { properties , namespace = 'basic' } = {}) {
      let newState = {};

      if (properties) {
        properties.forEach(prop => newState[prop] = initialState[namespace][prop]);
      } else {
        newState = _.cloneDeep(initialState);
        namespace = undefined;
      }

      commit('setProperties', { properties: newState, namespace });
    },
    async deleteWarranty({ dispatch }, { id }) {
      const group = {
        id: id,
        type: 'warranty'
      };
      await dispatch('settings/workflowGroups/deleteGroup', { group }, { root: true });
    },
    async updateWarrantyState({ commit }, { id, newState }) {
      try {
        await WarrantyPolicies.updateState(id, newState);
      } catch (error) {
        commit('setToast', {
          message: 'Unable to update warranty policy state',
          type: 'error',
        }, { root: true });
      }
    },
    async getProductTypes({ commit }) {
      const regions = await Regions.getAll();
      commit('setBasicProperty', {
        key: 'allRegions',
        value: regions,
      });
    },
    async getRegions({ commit }) {
      const productTypes = await Product.getTypes();
      commit('setBasicProperty', {
        key: 'allProductTypes',
        value: productTypes,
      });
    }
  },
  getters: {
    warranties: (state, getters, rootState, rootGetters) => rootGetters['settings/workflowGroups/groupsByType']('warranty'),
    currentId: (state) => state.basic.workflowGroupId,
    saving: (state, getters, rootState, rootGetters) => state.state === 'saving' || rootGetters['settings/workflowGroups/groupTypeState'] === 'saving',
    loading: (state, getters, rootState, rootGetters) => state.state === 'loading' || rootGetters['settings/workflowGroups/groupTypeState'] === 'loading',
    errors: (state) => state.errors,
  }
};
